<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-data-table
        :headers="headers"
        :items="listProviders"
        :loading="isLoadingProviders"
        :server-items-length="providersMeta.totalItems"
        :options.sync="options"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <template v-v-if="$admin.can('provider-update')">
            <v-tooltip top v-if="item.status == 'active'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  small
                  class="mr-2"
                  @click="toggleItemStatus(item)"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span>Désactiver le fournisseur</span>
            </v-tooltip>
            <v-tooltip top v-else-if="item.can_be_activated == 1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  small
                  class="mr-2"
                  @click="toggleItemStatus(item)"
                >
                  mdi-check-outline
                </v-icon>
              </template>
              <span>Activer le fournisseur</span>
            </v-tooltip>
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("providers") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addItem()">
              {{ $t("add_new_provider") }}
            </v-btn>
            <v-btn
              v-if="$admin.can('provider-export')"
              color="primary"
              @click="getCSV(options)"
              :loading="is_loading_csv"
              :disabled="is_loading_csv"
            >
              <v-icon color="white" class="mr-2">
                mdi-arrow-down-bold-circle-outline
              </v-icon>
              {{ $t("export_result_csv") }}
            </v-btn>
            <delete-providers-dialog
              v-if="$admin.can('provider-delete')"
            ></delete-providers-dialog>
          </v-toolbar>
          <v-row class="mx-2 mx-0">
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="options.search"
                :label="$t('search')"
                class=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                clearable
                v-model="options.status"
                :items="['active', 'inactive']"
                :label="$t('status')"
                :disabled="is_loading_providers"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    DeleteProvidersDialog: () => import("./provider-dialogs/Delete.vue"),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_providers = true;
        await this.$store.dispatch("providers/list", this.options).then(() => {
          this.is_loading_providers = false;
          if (this.options.page > this.providersMeta?.lastPage) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      isLoadingProviders: "providers/isLoadingProviders",
      listProviders: "providers/listProviders",
      providersMeta: "providers/meta",
    }),
  },

  data() {
    return {
      options: {},
      is_loading_providers: false,
      is_loading_csv: false,
      headers: [
        {
          text: this.$t("provider_id"),
          align: "start",
          value: "code",
          width: "5%",
        },
        {
          text: this.$t("company_name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  methods: {
    toggleItemStatus(item) {
      this.$store.dispatch("providers/toggleStatus", item);
    },
    editItem(item) {
      this.$router.push({
        name: "setup.providers.edit",
        params: { id: item.id },
      });
    },

    addItem() {
      this.$store.dispatch("providers/openEditForm");
      this.$router.push({ name: "setup.providers.new" });
    },

    deleteItem(item) {
      this.$store.dispatch("providers/openDeleteForm", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("providers/downloadCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "providers.csv");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_csv = false;
        });
    },
  },
  async created() {
    this.$store.commit("SET_PAGE_SUBTITLE", "");
    this.$store.commit("SET_SUBTITLE_ID", "");
  },
};
</script>
